<template>
  <div class="flex h-full justify-center items-center">
    <div class="h-full flex justify-center items-center">
      <p>{{ params.myid }}</p>
      <feather-icon
        v-if="params.is_late"
        icon="AlertTriangleIcon"
        :svg-classes="['h-8 w-8 text-danger']"
        class="ml-4"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "AgTableDangerIcon"
});
</script>

<style scoped>
.iconColor {
  color: #6B9D18;
}
</style>
