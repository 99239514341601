<template>
  <vs-card class="flex vsCard justify-center items-center h-full py-6">
    <div class="labelHeader flex justify-center items-center flex-wrap gap-4 h-full">
      <div class="px-6 py-3">
        قيد المراجعة
      </div>
      <div class="px-6 py-3">
        مقبول
      </div>
      <div class="px-6 py-3">
        جاهز
      </div>
      <div
        v-show="isShowAllStatus"
        class="px-6 py-3"
      >
        قيد التوصيل
      </div>
      <div
        v-show="isShowAllStatus"
        class="px-6 py-3"
      >
        تم التوصيل
      </div>
      <div class="px-6 py-3">
        تم الرفض
      </div>
    </div>
  </vs-card>
</template>

<script>
export default {
  name: "OrderStatus",
  props: {
    isShowAllStatus: {required: true, type: Boolean},
  }
};
</script>

<style>

.labelHeader div {
  color: #ffffff;
  border-radius: 5px;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
}

.labelHeader div:nth-child(1) {
  background-color: #FE948E;
}

.labelHeader div:nth-child(2) {
  background-color: #5F7A99;
}

.labelHeader div:nth-child(3) {
  background-color: #8158A4;
}

.labelHeader div:nth-child(4) {
  background-color: #6495ED;
}

.labelHeader div:nth-child(5) {
  background-color: #9ECC3B;
}

.labelHeader div:nth-child(6) {
  background-color: #EF4141;
}

.vsCard .vs-card--content {
  margin-bottom: 0px !important;
}


</style>
